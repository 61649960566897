// picture Preview
export function previewImg (fileId, imgId, valId) {
  var pic = document.getElementById(imgId)
  var file = document.getElementById(fileId)
  var inputUrl = document.getElementById(valId)
  var ext = file.value.substring(file.value.lastIndexOf('.') + 1).toLowerCase()
  if (ext === '' || ext === null) {
    return
  } else if (ext !== 'png' && ext !== 'jpg' && ext !== 'jpeg') {
    alert('图片的格式必须为png或者jpg或者jpeg格式！')
    return
  }
  file = file.files[0]
  var reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function (e) {
    pic.src = this.result
    inputUrl.value = this.result
  }
}
