<template>
  <div class="edit">
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div class="content" ref="content">
      <div class="box clearfix">
        <user-left :name="'case'"></user-left>
        <div class="user-right" ref="right">
          <div class="title-top"><strong>案例轨迹</strong></div>
          <div class=""></div>
        </div>
      </div>
    </div>
    <!--底部-->
    <footer-nav></footer-nav>
  </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav'
import FooterNav from '@/components/FooterNav'
import {previewImg} from '@/libs/previewImg'
import UserLeft from '@/components/UserLeft'

export default {
  name: 'user',
  data () {
    return {
      current: 'user',
      is_avatar: false,
      userinfo: {
        nickname: '',
        name: '',
        age: '',
        avatar: '',
        company: '',
        position: ''
      }
    }
  },
  methods: {
    previewImage: function (fileId, imgId) {
      previewImg(fileId, imgId)
    },
    submitForm: function () {
      // 提交修改
      let img = document.getElementById('photoImg')
      let imgFiles = document.getElementById('photoFile')
      if (!this.userinfo.nickname) {
        this.$layer.msg('请输入昵称！')
        return true
      }
      if (!this.userinfo.company) {
        this.$layer.msg('请输入单位名称！')
        return true
      }
      if (!this.userinfo.position) {
        this.$layer.msg('请输入个人职称！')
        return true
      }
      let image = ''
      if (imgFiles.files[0]) {
        image = img.src
      } else {
        image = ''
      }
      let params = {
        nickname: this.userinfo.nickname,
        company: this.userinfo.company,
        position: this.userinfo.position,
        avatar: image
      }
      this.$post('update_profile', params).then(res => {
        this.$layer.msg(res.msg)
      }).catch(response => {
        this.$layer.msg('网络异常，请稍后重试！')
      })
    }
  },
  components: {
    HeaderNav,
    FooterNav,
    UserLeft
  },
  mounted: function () {
    var h = document.documentElement.clientHeight || document.body.clientHeight
    this.$refs.content.style.minHeight = h - 124 + 'px'
    this.$refs.right.style.minHeight = h - 144 + 'px'
  },
  created () {
    // 获取用户信息
  }
}
</script>

<style lang="less" scoped>
@import url('../assets/css/page/edit');
</style>
